import '../TermosDeServicoAnual/termos.css';

export default function TermosMensal() {
  return (
    <div className="body-termos">
      <h1>Termos de Uso do Curso Online FullStack Var Solutions - Pagamento Mensal</h1>
      <h2>1. Aceitação dos Termos</h2>
      <p>Ao se inscrever e acessar o Curso Online FullStack Var Solutions (doravante referido como "Curso"), você concorda em cumprir integralmente os seguintes termos e condições de uso. Se você não concordar com algum destes termos, por favor, não prossiga com a inscrição ou acesso ao Curso.</p>
      <h2>2. Acesso ao Curso</h2>
      <p><strong>2.1. </strong>O Curso é um serviço de assinatura online com pagamento mensal e pode ser cancelado a qualquer momento.</p>
      <p><strong>2.2. </strong>Durante o período de assinatura mensal, você terá acesso contínuo ao conteúdo do Curso, que inclui aulas, módulos, materiais didáticos e recursos adicionais, desde que esteja em conformidade com os termos estabelecidos aqui.</p>
      <h2>3. Propriedade Intelectual</h2>
      <p><strong>3.1. </strong>Todo o conteúdo disponibilizado no Curso, incluindo, mas não limitado a textos, vídeos, imagens, códigos, documentos e quaisquer outros materiais, é protegido por direitos autorais e outros direitos de propriedade intelectual.</p>
      <p><strong>3.2. </strong>Você concorda em usar o conteúdo do Curso apenas para fins educacionais e não comerciais. Qualquer reprodução, distribuição ou exploração não autorizada do conteúdo é estritamente proibida.</p>
      <h2>4. Uso Adequado</h2>
      <p><strong>4.1. </strong>Você se compromete a utilizar o Curso de maneira ética e responsável, respeitando instrutores, colegas participantes e a integridade da plataforma.</p>
      <p><strong>4.2. </strong>Suas credenciais de acesso ao Curso são pessoais e não devem ser compartilhadas com terceiros. O acesso ao Curso é restrito ao titular da conta.</p>
      <h2>5. Cancelamento e Pagamento</h2>
      <p><strong>5.1. </strong>Você pode cancelar sua assinatura a qualquer momento, e o pagamento será interrompido a partir do próximo ciclo mensal.</p>
      <p><strong>5.2. </strong>Não haverá reembolso parcial por cancelamento durante um ciclo de pagamento mensal já iniciado.</p>
      <h2>6. Alterações nos Termos</h2>
      <p><strong>6.1. </strong>Reservamo-nos o direito de modificar estes termos a qualquer momento. As alterações entrarão em vigor após sua publicação na plataforma.</p>
      <p><strong>6.2. </strong>É de sua responsabilidade revisar periodicamente os termos para estar ciente de quaisquer mudanças.</p>
      <h2>7. Responsabilidade e Garantias</h2>
      <p><strong>7.1. </strong>O Curso é fornecido "como está", sem garantias de qualquer tipo, expressas ou implícitas. Não nos responsabilizamos por quaisquer danos decorrentes do uso ou incapacidade de uso do Curso.</p>
      <p><strong>7.2. </strong>Nossa responsabilidade total em relação a você por qualquer reivindicação relacionada ao Curso não excederá o valor total pago pela assinatura até o momento do cancelamento.</p>
      <h2>8. Jurisdição</h2>
      <p><strong>8.1. </strong>Estes termos são regidos pelas leis brasileiras, excluindo seus conflitos de disposições legais.</p>
      <p><strong>8.2. </strong>Quaisquer disputas decorrentes destes termos ou do Curso serão submetidas à jurisdição exclusiva dos tribunais competentes no Brasil.</p>
      <br />
      <p>Ao prosseguir com a inscrição no Curso Online FullStack Var Solutions, você confirma que leu, compreendeu e concordou com todos os termos apresentados acima. Em caso de dúvidas, entre em contato conosco por meio dos canais de suporte designados.</p>
    </div>
  );
}
