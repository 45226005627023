import RoutesApp from './routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div>
      <BrowserRouter>
        <RoutesApp/>
        <ToastContainer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
